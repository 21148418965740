$(document).ready(function(){
	$.fn.notif = function(options){
		
		var settings = {
			html : '<div class="notification animated fadeInLeft {{cls}}">\
			            <div class="left">\
			              {{#icon}}\
			              	<div class="icon">{{{icon}}}</div>\
			              {{/icon}}\
			              {{#img}}\
			              	<div class="img" style="background-image : url({{img}});"></div>\
			              {{/img}}\
			            </div>\
			            <div class="right">\
			              <h2>{{title}}</h2>\
			              <p>{{content}}</p>\
			            </div>\
			          </div>',
			icon : '&#8505;',
			timeout : false
		}

		if(options.cls == 'error'){
			settings.icon = '&#10060;';
		}
		if(options.cls == 'success'){
			settings.icon = '&#128077;';
		}

		var options = $.extend(settings, options);

		return this.each(function(){
			var $this = $(this);
			var $notifs = $('> .notifications', this);

			var $notif = $(Mustache.render(options.html, options));

			if($notifs.length == 0){
				$notifs = $('<div class="notifications animated flipInX"/>');
				$this.append($notifs);
			}
			$notifs.append($notif);

			if(options.timeout && options.timeout != 0){
				setTimeout(function(){
					$notif.trigger('click');
				}, options.timeout);
			}
			$notif.click(function(event){
				event.preventDefault();
				$notif.addClass('fadeOutLeft').delay(500).slideUp(300 ,function(){
					if($notif.siblings().length == 0){
						$notifs.remove();
					}
					$notif.remove();
				});
			})
		});
	}
/*
	$('body').notif({title:'Erreur', content:'Mon contenu', cls:'success'});
	$('body').notif({title:'Erreur', content:'Mon contenu', timeout:'2000'});
	$('body').notif({title:'Erreur', content:'Mon contenu', img:'http://localhost/Starters/Admin/images/login/icon.png'});*/

    $('.notifications').click(function(event){
        event.preventDefault();
        $(this).addClass('fadeOutLeft').delay(500).slideUp(300 ,function(){
            if($(this).siblings().length === 0){
                $(this).remove();
            }
            $(this).remove();
        });
    });
});